<template>
    <div class="inner-section">
      <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGarden.green_leaf_transfer_factory') }} {{ $t('globalTrans.search') }}</h4>
      </template>
        <!-- search section start -->
        <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-group
                label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.fiscal_year')}}
                  </template>
                  <b-form-select
                    plain
                    id="clone_info_id"
                    :options="fiscalYearList"
                    v-model="search.fiscal_year_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
      </card>
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('teaGarden.green_leaf_transfer_factory') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:headerAction>
          <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadingState">
            <b-row>
              <b-col sm="12">
                  <div class="quick-filter-wrapper">
                    <div class="quick-filter-left">
                      <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                        <b-dropdown-form>
                          <div
                            class="form-group"
                            v-for="(field, index) in labelData"
                            :key="index"
                            >
                              <b-form-checkbox
                                :id="'checkbox-' + field.labels"
                                v-model="field.show"
                                :name="'checkbox-' + field.labels"
                                value=1
                                unchecked-value=0
                              >
                                {{ $t(field.labels)  }}
                              </b-form-checkbox>
                          </div>
                        </b-dropdown-form>
                      </b-dropdown>
                    </div>
                    <div class="quick-filter-right">
                      <b-form-group
                        :label="$t('menu.perpage')"
                        label-for="per-page-select"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="search.limit"
                          :options="pageOptions"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="table-responsive">
                <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                  <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                    <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(type)="data">
                      {{ getTypeName(data.item.type) }}
                    </template>
                    <template v-slot:cell(garden_id)="data">
                      {{ getGardenName(data.item.garden_id) }}
                    </template>
                    <template v-slot:cell(factory_id)="data">
                      {{ getFactoryName(data.item.factory_id) }}
                    </template>
                    <template v-slot:cell(stock_in_quantity)="data">
                      {{ $n(data.item.stock_in_quantity) }}
                    </template>
                     <template v-slot:cell(stock_date)="data">
                      {{ data.item.stock_date | dateFormat}}
                    </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-warning" v-if="data.item.status === 1">{{$t('globalTrans.draft')}}</span>
                    <span class="badge badge-success" v-else>{{$t('globalTrans.submitted')}}</span>
                  </template>
                    <template v-slot:cell(action)="data">
                      <b-button class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')" @click="edit(data.item)" v-b-modal.modal-details>
                      <i class="ri-eye-line"></i>
                    </b-button>
                      <b-button v-b-modal.modal-form variant=" iq-bg-success mr-1" size="sm" @click="editMethodInParent(data.item, 1)" class="action-btn edit" v-if="data.item.status === 1 && data.item.inserted_by === 1" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                    </template>
                  </b-table>
                </slot>
                <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                  <b-pagination
                    v-model="pagination.currentPage"
                    :perPage="search.limit"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                 </div>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
        <!-- table section end -->
      </body-card>
      <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
      </b-modal>
      <!-- details modal -->
      <b-modal id="modal-details" size="lg" :title="detailsTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Details :id="editItemId" :key="editItemId"/>
      </b-modal>
    </div>
  </template>
  <script>
  import Form from './Form.vue'
  import Details from './Details.vue'
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { greenLeafTransferFactoryListApi, providentFundToggle } from '../../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form,
      Details
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDirection: 'desc',
        sortDesc: true,
        search: {
          fiscal_year_id: 0,
          limit: 20
        },
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
          { labels: 'teaGarden.factory_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
          { labels: 'teaGardenConfig.garden_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
          { labels: 'teaGardenConfig.factory_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
          { labels: 'teaGarden.stock_in_quantity', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: 'auto' } },
          { labels: 'teaGarden.stock_in_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: 'auto' } },
          { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 8, thStyle: { width: '12%' } }
        ],
        actions: {
          edit: true,
          toogle: true,
          delete: false
        }
      }
    },
    created () {
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      detailsTitle () {
        return this.$t('teaGarden.green_leaf_transfer_factory') + ' ' + this.$t('globalTrans.details')
      },
      factoryTypeList () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Gardens Fatory' : 'বাগানের নিজস্ব কারখানা' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Bought Leaf Factory' : 'বটলীফ কারখানা' }
        ]
      },
      fiscalYearList () {
       return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      insertedByList () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'System' : 'Stysem' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Direct' : 'সরাসরি' }
        ]
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'type' },
            { key: 'garden_id' },
            { key: 'factory_id' },
            { key: 'stock_in_quantity' },
            { key: 'stock_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'type' },
            { key: 'garden_id' },
            { key: 'factory_id' },
            { key: 'stock_in_quantity' },
            { key: 'stock_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('teaGarden.green_leaf_transfer_factory') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGarden.green_leaf_transfer_factory') + ' ' + this.$t('globalTrans.modify')
      }
    },
    methods: {
      editMethodInParent (item, type) {
        if (type === 1) {
          this.edit(item)
        } else if (type === 2) {
          this.changeStatus(3, teaGardenServiceBaseUrl, providentFundToggle, item)
        }
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, greenLeafTransferFactoryListApi, params)
        if (result.success) {
          const listData = result.data.data.map((item, index) => {
            const tmpType = this.factoryTypeList.find(el => el.value === item.return_type)
            const gardData = {
            type_en: tmpType !== undefined ? tmpType.text_en : '',
            type_bn: tmpType !== undefined ? tmpType.text_bn : ''
          }
            return Object.assign({}, item, gardData, { serial: index })
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
            return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
            return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      },
      getTypeName (id) {
          const obj = this.$store.state.TeaGardenService.commonObj.garrdenFactoryTypeList.find(item => item.value === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getGardenName (id) {
        const objectData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
        if (typeof objectData !== 'undefined') {
            return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
          } else {
            return ''
          }
      },
      getFactoryName (id) {
        const objectData = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === id)
        if (typeof objectData !== 'undefined') {
            return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
          } else {
            return ''
          }
      }
    }
  }
  </script>
