<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form ref="form"  @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Factory Type" vid="type" rules="required|min_value:1">
              <b-form-group
                label-for="type"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGarden.factory_type')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="data.type"
                :options="factoryTypeList"
                id="type"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
              <b-form-group
                label-for="garden_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGardenConfig.garden_name')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="data.garden_id"
                :disabled="isGardenAdminCheckGardenId() ? true : false"
                @change="getGfAutoId(data.garden_id)"
                :options="masterTeaGardenGenInfoList"
                id="garden_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="data.type === 3">
            <ValidationProvider name="Factory" vid="factory_id" :rules="{required: false}">
              <b-form-group
                label-for="factory_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGardenConfig.factory_name')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="data.factory_id"
                :options="masterBoughtLeafFactoryList"
                id="factory_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value='null'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
              <b-form-group
                label-for="fiscal_year_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="data.fiscal_year_id"
                :options="fiscalYearList"
                id="fiscal_year_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Date" vid="stock_date" rules="required">
              <b-form-group
                class="row"
                label-for="stock_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('globalTrans.date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="stock_date"
                  v-model="data.stock_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Current Stock Quantity (Kg)" vid="current_stock" :rules="{required:false}">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="current_stock">
                <template v-slot:label>
                  {{ $t('teaGarden.current_stock') }}  ({{ $t('globalTrans.kg') }})
                </template>
                <b-form-input
                  disabled
                  id="current_stock"
                  :value="current_stock_loading ? $t('globalTrans.loading') : current_stock"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Stock In Quantity (Kg)" vid="stock_quantity" rules="required|min_value:1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="stock_quantity">
                <template v-slot:label>
                  {{ $t('teaGarden.stock_in_quantity') }} ({{ $t('globalTrans.kg') }})<span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="stock_quantity"
                  v-model="data.stock_quantity"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <!-- <b-button type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
            <b-button type="button" @click="saveUpdate()" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
            <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
            <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { greenLeafTransferFactoryUpdateApi, greenLeafTransferFactoryStoreApi, getGardenCurrentStock } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
  data () {
    return {
      isGardenAdmin: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        type: 0,
        garden_id: 0,
        factory_id: null,
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        stock_date: '',
        stock_quantity: 0,
        inserted_by: 1
      },
      current_stock: 0,
      current_stock_loading: false,
      has_own_factory: 0,
      status: 1,
      gardenList: [],
      factoryList: []
    }
  },
  created () {
    if (this.id) {
            const tmp = this.getData()
            this.data = tmp
        } else {
            if (this.isGardenAdminCheckGardenId()) {
                const gardenObj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.isGardenAdminCheckGardenId())
                if (gardenObj.has_own_garden === 1) {
                  this.data.type = 2
                } else {
                  this.data.type = 3
                }
                this.data.garden_id = this.isGardenAdminCheckGardenId()
            }
        }
    this.getCurrentStock()
 },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    factoryTypeList () {
      return [
          { value: 2, text: this.$i18n.locale === 'en' ? 'Gardens Fatory' : 'বাগানের নিজস্ব কারখানা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Bought Leaf Factory' : 'বটলীফ কারখানা' }
      ]
    },
    insertedByList () {
      return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'System' : 'Stysem' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Direct' : 'সরাসরি' }
      ]
    },
    masterTeaGardenGenInfoList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    masterBoughtLeafFactoryList () {
      return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.factory_type_id === 2)
    }
  },
  watch: {
    'data.garden_id': function (newVal, oldval) {
      if (newVal) {
        this.getCurrentStock()
      }
    },
    'data.type': function (newVal, oldVal) {
      if (newVal && newVal === 2) {
        this.data.factory_id = null
      }
    }
  },
  methods: {
    finalSave () {
        this.$swal({
          title: this.$t('globalTrans.final_save_msg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.status = 2
            this.saveUpdate()
          }
        })
    },
    getGfAutoId (Id) {
        this.data.factory_id = null
        const list = this.masterTeaGardenGenInfoList
        const Obj = list.find(el => el.value === parseInt(Id))
        if (Obj.has_own_garden === 1) {
          this.data.type = 2
        } else {
          this.data.type = 3
        }
    },
    async saveUpdate () {
        let result = null
        this.loader = true
        this.data.status = this.status
        var check = await this.$refs.form.validate()
        if (check) {
          if (parseInt(this.data.stock_quantity) > parseInt(this.current_stock)) {
            this.$toast.success({
              title: this.$t('globalTrans.warning'),
              message: this.$t('teaGarden.stock_in_quantity_error_title'),
              color: 'red'
            })
            return false
          }
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          const loadingState = { loading: false, listReload: false }
          if (this.data.id) {
            result = await RestApi.putData(teaGardenServiceBaseUrl, `${greenLeafTransferFactoryUpdateApi}/${this.data.id}`, this.data)
          } else {
            result = await RestApi.postData(teaGardenServiceBaseUrl, greenLeafTransferFactoryStoreApi, this.data)
          }
          this.loader = false
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          if (result.success) {
            this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$bvModal.hide('modal-form')
            } else {
            this.$refs.form.setErrors(result.errors)
          }
        }
    },
    async getCurrentStock () {
        this.current_stock_loading = true
        let result = null
        result = await RestApi.getData(teaGardenServiceBaseUrl, getGardenCurrentStock, { garden_id: this.data.garden_id })
        if (result.success) {
          this.current_stock = result.data
        } else {
          this.current_stock = 0
        }
        this.current_stock_loading = false
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
